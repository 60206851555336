.ff-quiz__inner {
  .ff-quiz__slide--4 {
    .ff-quiz__question {
      > li {
        width: 20%;
      }
      width: 78% !important;
    }
    .ff-quiz__intensity-stage {
      .ff-quiz__intensity-image {
        font-size: 155px;
      }
    }
  }
}
