.colorbox {
  &__estee-edit {
    background: $color-navy;
    #cboxContent {
      background: $color-navy;
      .cboxIframe {
        background: $color-navy;
      }
    }
  }
}

#colorbox.colorbox__wishlist-confirm {
  #cboxContent {
    #cboxClose {
      top: 5px !important;
      right: 5px !important;
    }
  }
}

.formatter-estee-edit__content {
  .ee-mb-tiles {
    .ee-mb-tile:nth-child(3) {
      .ee-mb-tile__text {
        margin-top: 0px;
      }
    }
  }
}

.ecommerce {
  &.section-store-locator {
    .store-locator {
      .local-search-form {
        .local-search-form__city {
          width: 100% !important;
        }
        .local-search-form__zip-wrapper {
          display: none;
        }
        .store-results {
          .doors-results__pager {
            padding: 0 0 0 10px;
            .doors-results__pager-pages {
              margin: 0 0 0 10px;
              padding: 0 0 0 10px;
              .doors-results__pager-page-link {
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
    }
  }
  #dimensions {
    .color-family {
      .color-family__swatch {
        margin-bottom: 10px;
        display: none;
      }
    }
  }
  .email_signup_sucess_popup {
    #cboxWrapper {
      height: 360px !important;
      #cboxClose {
        right: 20px !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .results-header-container {
      .results-pagination {
        .view-all {
          @include swap_direction(padding-right, 4px);
        }
        .page {
          @include swap_direction(padding, 0px 3px 0px 4px);
        }
      }
    }
  }
  #product-results {
    width: 800px;
    .search-product__details {
      width: 415px;
      @include swap_direction(margin-left, 24px);
      .selectBox-dropdown {
        width: auto;
      }
    }
  }
  @media screen and (max-width: 768px) {
    #product-results {
      width: 520px;
      .search-product__details {
        width: 328px;
        @include swap_direction(margin-left, 38px);
        .search-product__button {
          @include swap_direction(margin-left, 0px);
          @include swap_direction(margin-top, 10px);
        }
      }
    }
  }
}

.footer-forms-location-sms-email-signup-elc-nodeblock {
  .footer-signup-email {
    width: 28%;
    .form-text {
      width: 65%;
    }
    .form-submit {
      width: 33%;
      margin-left: 2%;
      letter-spacing: 0.05em;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .footer-forms-location-sms-email-signup-elc-nodeblock {
    .footer-signup-email {
      width: 50%;
    }
  }
  #colorbox.colorbox__quickshop {
    .quickshop {
      .quickshop__image-container {
        height: auto;
      }
    }
  }
}

.brand-aerin {
  .aerin-fragrance-elc-mpp {
    .product_quick_view {
      .product_img {
        padding-top: 0;
      }
      .aerin-product-name {
        padding-top: 0;
      }
      .product-price {
        margin-top: 0.5em;
      }
      h2.aerin-product-name {
        display: inline-block;
        margin: 0 12%;
        padding: 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .page-wrapper {
    .main-inner {
      .mpp {
        #mpp__filter-tab {
          display: none;
        }
        .mpp__product-sort-container {
          top: initial;
        }
        .mpp__product-grid {
          margin-top: 35px;
        }
      }
    }
  }
}

#colorbox.colorbox__quickshop {
  .quickshop__headers {
    max-height: 375px;
  }
  .quickshop__tab--description {
    display: block;
    margin-top: 10px;
    line-height: 16px;
  }
  .quickshop__tab-container {
    height: 210px;
    p {
      margin: 10px 0;
      line-height: 16px;
    }
  }
  .quickshop {
    ul.shade-list {
      margin: 5px 0 0 0;
    }
  }
}

.node-spp-related-content-item-set {
  .spp-product__how-to-use {
    .spp__steps {
      .headline--secondary {
        word-wrap: break-word;
        min-height: 100px;
        line-height: 50px;
        letter-spacing: -3.5px;
        width: 260px;
      }
    }
  }
}

.intro-block_buy_btn.active {
  right: 0 !important;
  margin-right: 0;
}

.brand-renutriv {
  #renutriv_section-4 {
    #carousel_wrapper {
      .experience_now {
        img {
          position: relative;
        }
      }
    }
  }
  .side_nav {
    .side_nav_list {
      li.propoElement {
        position: relative;
      }
    }
  }
}

#colorbox.welcome15-overlay {
  .email_popover {
    text-align: center;
    .email_input {
      input.form-text {
        float: none;
        margin-bottom: 10px;
      }
    }
  }
}

#colorbox.signin-overlay-wrapper {
  .registration-confirmation {
    .sign-in-component__header {
      text-align: center;
    }
    .sign-in-component__confirm-registration-fields {
      .sign-in-component__confirm-registration-field-wrapper {
        width: 60%;
      }
    }
  }
  #cboxWrapper {
    overflow: scroll;
    box-sizing: border-box;
  }
  #cboxContent {
    padding-top: 0;
    .sign-in-component {
      &_ru {
        &__header {
          margin: 20px auto;
        }
        &__container {
          .social-login-container {
            &__social-network {
              @include swap_direction(margin, 5px 22px);
            }
          }
        }
        &__forms-container {
          input[type='submit'] {
            margin-top: 10px;
          }
        }
        &__legal-container {
          width: 450px;
        }
      }
      &__form {
        .form-item {
          margin-bottom: 10px;
          &.form-item-legal:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.email_popover {
  .email_popover__social_icons {
    text-align: center;
    width: 100%;
    .twitter,
    .pinterest,
    .instagram,
    .youtube {
      display: none;
    }
    .facebook {
      img {
        float: none;
      }
    }
  }
}

.colorbox__wishlist-confirm {
  #cboxWrapper {
    #cboxLoadedContent {
      min-height: 220px !important;
    }
  }
}

.mpp {
  &__product-grid {
    .mpp__product {
      min-height: 680px;
      .product_brief__buttons-container {
        .product_brief__sub-panel {
          .product_brief__price-size-select {
            text-transform: lowercase;
          }
        }
      }
    }
  }
}

.spp,
.page-product {
  .spp-product__anchor-tab {
    top: initial;
    bottom: 0px;
  }
  .spp-product__details-container {
    margin: 0 auto 20px auto;
  }
  .spp-product__reviews {
    margin-bottom: 70px;
  }
  .product-full__personal {
    padding-top: 0;
    .social-share-icons {
      .twitter,
      .pinterest {
        display: none;
      }
    }
  }
  .product-full {
    .free_standard_shipping_returns {
      font-weight: normal;
      padding: 15px 0;
    }
    &__share {
      display: none;
    }
    #delivery_info {
      margin: 0;
      display: none;
      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .edd {
      display: block;
      &.hide {
        display: none;
      }
    }
  }
}

.back-bar {
  .back-bar__link {
    width: 90px;
  }
}

.contact-us-page {
  .contact-form {
    .contact-form__section.address-form {
      .address-form__address1-container,
      .address-form__registered-container,
      .address-form__address2-container,
      .address-form__country-container,
      .address-form__address-type-container {
        padding-left: 0px;
      }
    }
  }
}

@media screen and (max-width: $xmedium-down) {
  .front {
    .home-formatter {
      .cta-button-wrapper {
        width: 80%;
        .cta__button {
          padding: 0 12px;
        }
        .button--inverted {
          line-height: 19px;
        }
      }
    }
  }
}

.side_nav {
  .side_nav_list {
    li.li1 {
      a.propoText {
        line-height: 20px !important;
      }
    }
  }
}

.ee-social-share {
  a.twitter,
  a.pinterest {
    display: none;
  }
  ul {
    li {
      margin: 0;
    }
  }
}

.flexslider {
  .mb-slide-share {
    li {
      padding: 0 45px;
    }
    a.twitter,
    a.pinterest {
      display: none;
    }
  }
}

.ff-results {
  .ff-results__matches-sub-headline,
  .ff-results__regimen-steps {
    display: none;
  }
  .ff-results__matches {
    display: none;
  }
}

#tips_boutique {
  .mpp-carousel {
    .carousel_container {
      height: 85% !important;
    }
    .carousel {
      li {
        height: 530px !important;
        .el-product_name {
          line-height: 17px;
        }
        .el-product_alt_description-text {
          line-height: 14px;
        }
        .el-product_price {
          margin-top: 2px;
        }
      }
    }
  }
}

.anr-night-experience {
  &__desc {
    height: 185px;
  }
  &__anr_quick_shop_container.darkened {
    position: absolute !important;
    bottom: 0;
    margin-left: 40px;
  }
  &__mini_carousel {
    min-height: 780px;
    position: relative;
  }
}

#anr-night-experience {
  &__moisturize_desc_1 {
    margin-bottom: 10.8% !important;
  }
  &__cleanse,
  &__strengthen,
  &__moisturize {
    width: 20% !important;
  }
}

#anr-night-experience__cleanse {
  margin-left: 2.05% !important;
  #anr-night-experience__anr-quick-shop-module9-1 {
    margin-left: 15% !important;
  }
}

#anr-night-experience__module1 {
  #anr-night-experience__carousel {
    #anr-night-experience__carousel-3 {
      #anr-night-experience__carousel3-video-link {
        margin-top: 9.5%;
        width: 11.5%;
      }
      .anr-night-experience__carousel-text {
        #anr-night-experience__carousel-3-text1 {
          text-align: left;
          margin-left: 6.5%;
        }
      }
    }
    #anr-night-experience__carousel-2 {
      .anr-night-experience__carousel-text {
        #anr-night-experience__carousel-2-text4 {
          line-height: 100%;
        }
        #anr-night-experience__carousel-2-text1 {
          margin-left: 6.5%;
        }
        #anr-night-experience__carousel-2-text2 {
          margin-left: 9.5%;
        }
        #anr-night-experience__carousel-2-text3 {
          margin-left: 9.5%;
          text-align: left;
        }
        #anr-night-experience__carousel-2-text5 {
          margin-left: 61.5%;
        }
        #anr-night-experience__carousel-2-text6 {
          margin-left: 50.3%;
        }
        #anr-night-experience__carousel-2-text7 {
          margin-left: 49.7%;
          width: 30%;
          text-align: right;
          word-wrap: break-word;
        }
        #anr-night-experience__carousel-2-text8 {
          margin-left: 54.5%;
        }
        #anr-night-experience__carousel-2-text9 {
          margin-left: 68%;
        }
        .pSpan57 {
          margin-left: -58.2%;
        }
      }
      .anr-night-experience__carousel2-video-link-container {
        margin-top: 36% !important;
        #anr-night-experience__carousel2-video-link {
          margin-left: 0%;
        }
      }
    }
    #anr-night-experience__carousel-4 {
      .anr-night-experience__carousel-text {
        #anr-night-experience__carousel-1-text5 {
          margin-left: 52%;
          line-height: 17px;
        }
        #anr-night-experience__carousel-1-text3 {
          margin-left: 51.6%;
          font-size: 520%;
          line-height: 70px;
          margin-bottom: 8%;
          text-align: left;
        }
        #anr-night-experience__carousel-1-text4 {
          width: 50%;
          text-align: left;
          margin-left: 51.2%;
        }
        #anr-night-experience__carousel-1-text1 {
          margin-left: 43.6%;
        }
        #anr-night-experience__carousel-1-text2 {
          margin-left: 28.6%;
        }
      }
    }
    #anr-night-experience__carousel-1 {
      .anr-night-experience__carousel-4-text4 {
        width: 50%;
        line-height: 62px;
      }
      p {
        letter-spacing: 0px;
      }
      .anr-night-experience__carousel-4-text1 {
        margin-top: 2.45% !important;
        width: 50%;
        margin-left: 7.5%;
      }
      .anr-night-experience__carousel-4-text5 {
        margin-top: 0.8% !important;
      }
      .anr-night-experience__carousel-4-text7 {
        letter-spacing: -0.7px;
      }
      .anr-night-experience__carousel-4-text8 {
        margin-top: 1%;
        letter-spacing: -0.7px;
      }
      .anr-night-experience__carousel4-video-link-wrapper {
        .anr-night-experience__carousel4-video-link-container {
          margin-top: 47.7% !important;
        }
      }
    }
  }
}

#anr-night-experience__repair {
  #anr-night-experience_repair_face__thumb_container {
    #anr-night-experience__desc_repair_face {
      .faceeye {
        margin-right: -13px;
      }
    }
  }
}

#anr-night-experience__module9 {
  .anr-night-experience__headings {
    letter-spacing: -3.2px;
  }
  .anr-night-experience__desc {
    height: 195px;
  }
  #anr-night-experience__right_repair {
    img {
      margin-top: 460%;
    }
  }
  #anr-night-experience__left_repair {
    img {
      margin-top: 435%;
    }
  }
}

#anr-night-experience__module11 {
  #anr-night-experience__text_energized_roll {
    #anr-night-experience__text_energized_ani {
      #anr-night-experience__text__energized_ani-hover {
        width: 100%;
      }
    }
  }
}

#anr-night-experience__carousel2 {
  #anr-night-experience__carousel2-text7 {
    width: 14.5%;
  }
  #anr-night-experience__carousel2-7 {
    #anr-night-experience__carousel2 {
      &-text52,
      &-stars6,
      &-text53,
      &-text54,
      &-text55 {
        display: none;
      }
    }
  }
  #anr-night-experience__carousel2-6 {
    #anr-night-experience__carousel2 {
      &-text40,
      &-stars4,
      &-text41,
      &-text42,
      &-text43 {
        display: none;
      }
    }
  }
  #anr-night-experience__carousel2-5 {
    #anr-night-experience__carousel2 {
      &-text32,
      &-stars3,
      &-text33,
      &-text34,
      &-text35 {
        display: none;
      }
    }
  }
  #anr-night-experience__carousel2-4 {
    #anr-night-experience__carousel2 {
      &-text24,
      &-stars2,
      &-text25,
      &-text26,
      &-text27 {
        display: none;
      }
    }
  }
  #anr-night-experience__carousel2-3 {
    #anr-night-experience__carousel2 {
      &-text16,
      &-stars1,
      &-text17,
      &-text18,
      &-text19 {
        display: none;
      }
    }
  }
}

.anr-simple__bg-full {
  .anr-night-experience__carousel-text {
    #anr-night-experience__expandable-2-text10 {
      letter-spacing: 1.3px;
    }
  }
}

.page-main {
  .mpp__product-sort-container {
    top: 12px;
    .mpp__product-sort {
      min-width: 195px;
    }
  }
}

.spp-product__how-to-use {
  .headline--secondary {
    font-size: 45px;
    min-height: 100px;
  }
}

.section-night-experience {
  .page-header {
    .page-branding {
      .page-branding__logo {
        background-size: 187px 26px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  #tips_boutique.boutique {
    .step.image-left {
      .step-text {
        margin-left: 48% !important;
      }
    }
    .tips_section {
      h1 {
        font-size: 56px;
      }
    }
  }
  .formatter-123__title-2 {
    padding-top: 100px !important;
  }
  .formatter-123__row-4.max-width {
    padding: 18px 23px;
  }
  .formatter-123__content--3-up {
    .promo-unit--onecol {
      padding: 18px 23px;
    }
  }
  .special-offer-row-promo-wrapper {
    .special-offer-row-promo {
      .special-offer-promo-text-wrapper {
        .special-offer-headline {
          font-size: 47px;
        }
      }
    }
  }
  .special-offer-row-wrapper {
    .special-offer-text-wrapper {
      .special-offer-headline {
        font-size: 84px !important;
      }
    }
  }
}

.foundation-finder-page {
  .ff-quiz__inner {
    .ff-quiz__coverage-image {
      font-size: 135px;
    }
    .ff-quiz__question {
      .ff-quiz__answer {
        .ff-quiz__headline--secondary {
          text-transform: capitalize;
        }
      }
    }
    .ff-quiz__coverage-stage {
      max-width: 745px;
    }
  }
  .chat-parallax.parallax__container {
    display: none;
  }
}

.ff-quiz__slide.ff-quiz__slide--2.slick-active {
  padding: 15px 0 0 0;
  h2.ff-quiz__headline {
    font-size: 55px;
    line-height: 45px;
    margin: 0 0 30px 0;
  }
  .ff-quiz__question {
    margin-bottom: 10px;
    .ff-quiz__checkbox {
      margin: 0 auto 5px auto;
    }
    h3.ff-quiz__headline--secondary {
      font-size: 28px;
      line-height: 25px;
    }
  }
}

.section-estee-stories-article-1-foundation-5-fresh-looks {
  .pc-carousel-formatter {
    .slick-dots {
      top: 59%;
    }
  }
}

.sign-in.registration {
  .form-submit {
    width: 225px;
  }
}

#colorbox.colorbox__quickshop {
  .quickshop__header {
    line-height: 40px;
    font-size: 42px;
  }
  .quickshop__price-size {
    padding: 10px 0 9px 0;
  }
  .quickshop__description {
    padding: 10px 29px 10px 0;
  }
  .quickshop__sub-header {
    font-size: 27px;
  }
}

.page-navigation__supplemental {
  a.slide__link {
    white-space: normal !important;
  }
}

.spp-product__mini-bag-button-container {
  .spp-product__mini-bag-add-button {
    width: 130px;
  }
}

.formatter-estee-edit__content {
  .ee-mb-tile:nth-child(4) {
    .ee-mb-tile__text {
      margin-top: 10px;
      h4 {
        line-height: 30px;
      }
    }
  }
  .ee-mb-tile__text {
    p {
      letter-spacing: 0.009em;
      line-height: 1.2em;
    }
  }
}

.responsive-carousel-nav {
  .js-flex-centered {
    .flex-viewport {
      overflow: visible !important;
      .slides {
        margin-left: 5%;
        li {
          float: left !important;
        }
      }
    }
  }
}

.responsive-carousel-nav:nth-child(1) {
  .js-flex-centered {
    .slides {
      margin-left: 10%;
    }
  }
}

.responsive-carousel-nav:nth-child(3) {
  .js-flex-centered {
    .slides {
      margin-left: 20%;
    }
  }
}

.responsive-carousel-nav:nth-child(4) {
  .js-flex-centered {
    .slides {
      margin-left: 25%;
    }
  }
}

#anr-night-experience__carousel-wrapper {
  #anr-night-experience__carousel-2 {
    .anr-night-experience__carousel-text {
      #anr-night-experience__carousel-2-text1 {
        margin-top: 0px !important;
      }
      #anr-night-experience__carousel-2-text3 {
        line-height: 80px;
      }
    }
  }
  #anr-night-experience__carousel-4 {
    #anr-night-experience__carousel-1-text3 {
      line-height: 100px;
    }
    #anr-night-experience__carousel-1-text1 {
      margin-top: 0 !important;
    }
  }
}

.filter-very_light {
  background: #ffd694;
}

.filter-light {
  background: #eec8ab;
}

.filter-very_light-light {
  background: #ffd694;
  border-right: 8px solid #eec8ab;
}

.page-footer {
  .page-sticky-footer {
    &__left {
      div {
        border-left: none !important;
      }
    }
  }
}

#rn_container {
  #renutriv_section-2 {
    .content {
      left: 56% !important;
    }
  }
}

@media only screen and (max-width: 1366px) and (min-width: 640px) {
  #tmb_container {
    #mb_boutique_quote-beauty {
      h3 {
        line-height: 4.15vw;
        width: 65%;
        font-size: 3.7vw;
      }
    }
  }
}

.page-header {
  min-height: 90px;
  .page-navigation {
    clear: both;
    margin-top: -3px;
  }
  &.alt-color-white {
    .el-search-block {
      top: unset;
    }
  }
}

.mpp {
  margin-top: 41px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .special-offer-row-promo-wrapper {
    .special-offer-row-promo {
      .special-offer-promo-text-wrapper {
        .special-offer-headline {
          font-size: 63px;
        }
      }
    }
  }
}

#rn_container {
  #renutriv_section-4 {
    #renutriv_section-4_li5 {
      .content p.first {
        margin-left: 0 !important;
      }
      .experience_now {
        img {
          left: 0 !important;
        }
      }
    }
  }
}

.formatter-123__row-4 {
  .basic-textarea-v1 {
    p {
      padding: 0 25px;
    }
  }
}

.home-block:nth-child(3) {
  .hero-block__headline.hero-block__headline--left {
    top: 12% !important;
    .header__headline {
      line-height: 75px;
    }
  }
}

.sign-in-component.registration {
  padding-top: 60px;
}

@media only screen and (min-width: 1025px) {
  .page-products-684 {
    .mpp {
      &__header {
        padding-left: 14%;
        text-align: left;
      }
    }
  }
  .page-products-687 {
    .mpp {
      &__header {
        padding-left: 19%;
        text-align: left;
      }
    }
  }
  .page-products-675 {
    .mpp {
      &__header {
        text-align: left;
        padding-left: 5%;
      }
    }
  }
  .page-products-679,
  .page-products-18449 {
    .mpp {
      &__header {
        text-align: left;
        padding-left: 10%;
      }
    }
  }
}

.page-products-re-nutriv {
  .page-main {
    .mpp__product-sort-container {
      top: 4px;
    }
  }
}

.page-products-12972 {
  .page-main {
    .mpp__product-sort-container {
      top: 40px;
    }
  }
}

.brand-aerin {
  .page-main {
    .mpp__product-sort-container {
      top: 6px;
    }
  }
}

.section-esteestories {
  .page-wrapper {
    .page-main {
      padding-top: 40px;
    }
  }
}

.special-offers-tray {
  .special-offers-tray__col.special-offer {
    h4.special-offer__header {
      font-size: 16px;
    }
    p.special-offer__text-header {
      font-size: 18px;
    }
    p.special-offer__text-content {
      font-size: 13px;
      line-height: 15px;
      text-transform: uppercase;
    }
    p.special-offer__terms {
      font-size: 12px;
    }
    p {
      margin: 10px 0;
    }
    a.button {
      text-decoration: underline;
      border: none;
      bottom: 0;
      left: 0;
    }
    p.special-offer__text {
      margin: 8px 0 10px;
    }
    img.special-offer__img {
      margin-left: 3px;
    }
  }
}

.brand-renutriv,
.is-alternate {
  .trust_mark_header {
    .trust_mark_header__link {
      background-image: url('/media/images/global/Trustmark_Logo_Estee_Lauder-white.png') !important;
    }
  }
}

.el-search-block {
  top: 75px;
}

#anr-night-experience__scroll {
  #anr-night-experience__scroll-text1 {
    margin-bottom: 0.25%;
    margin-top: 1.5%;
  }
  #anr-night-experience__scroll-text2-1 {
    font-size: 242%;
    line-height: 105%;
  }
  #anr-night-experience__scroll2-text5 {
    line-height: 75%;
  }
  #anr-night-experience__scroll2-text6,
  #anr-night-experience__scroll2-text6-1,
  #anr-night-experience__scroll2-text6-2,
  #anr-night-experience__scroll2-text6-3,
  #anr-night-experience__scroll2-text6-4 {
    line-height: 105%;
  }
}

#lpChat {
  .lp_pages_area {
    .lp_radio_button {
      input[type='radio'] ~ label {
        &::before,
        &::after {
          visibility: hidden;
        }
      }
    }
  }
}

#colorbox.edd_popover {
  #cboxContent {
    .order_arrival_details {
      margin-top: 35px;
    }
  }
}

.header-items {
  .page-utilities {
    .page-utilities {
      &__signin-text {
        text-align: right;
        .user-join-loyalty {
          &__link {
            padding-right: 20px;
          }
        }
      }
    }
    &__cart {
      &-count {
        min-width: 64px !important;
      }
    }
  }
}

.anr-night-experience__module-wrapperBottom {
  .anr-night-experience_mini_product_info_body {
    padding-left: 15px;
    padding-right: 15px;
  }
  .anr-night-experience__mini_carousel {
    min-height: 820px;
  }
}

.spp-product__details {
  .spp-product__details-attribute__label {
    font-family: 'AkzidenzGrotesk W1G' !important;
  }
}

.hero-block--renutriv {
  .hero-block__headline {
    top: 5% !important;
    left: 22% !important;
    h1.header__headline {
      font-family: 'BauerBodoniStd' !important;
      font-size: 101px;
      line-height: 90px;
    }
  }
}

.header-items {
  .page-utilities {
    .page-utilities__signin-text {
      .user-join-loyalty__link {
        text-transform: none;
      }
    }
  }
}

@media only screen and (min-width: 737px) {
  .section-customer-service,
  .section-store-locator,
  .section-estee-stories-brand-story-chapter-1-born-beauty,
  .section-customer-service-landing,
  .section-customer-service-shipping {
    .page-wrapper {
      padding-top: 110px;
    }
  }
}

.ee-landing__filters__side__wrapper {
  top: 150px;
}

.ee-slick-carousel__logo.logo-scale {
  top: 93px;
}

.email_popover {
  .instagram {
    display: block !important;
  }
  .youtube {
    display: block !important;
  }
  .facebook {
    img {
      float: left !important;
    }
  }
}

.spp__steps {
  div.spp-howtouse:first-of-type {
    .headline--secondary:first-of-type {
      width: 277px !important;
      margin-left: -4px;
    }
  }
}

.brand-aerin {
  #colorbox.colorbox__quickshop,
  #product_brief__quickshop__container {
    .quickshop,
    .quickshop__reviews,
    h3,
    h4 {
      font-family: 'futurabookc' !important;
    }
    .quickshop__attribute {
      p {
        font-family: 'futurabookc' !important;
      }
    }
    .quickshop__tabs-control {
      li {
        font-family: 'futurabookc' !important;
      }
    }
  }
}

.extended-mpp {
  .mpp__product {
    .product_brief__button-panel.button-color-picker {
      width: 138px;
      font-size: 11px;
    }
  }
}

.extended-mpp {
  .mpp__product {
    .product_brief__buttons--non-shaded {
      .product_brief__button--benefits {
        letter-spacing: 1px;
        width: 138px;
        font-size: 11px;
      }
    }
  }
}

.ee-article-products {
  &__list {
    .slick-slider {
      .slick {
        &-prev {
          left: -30px;
        }
        &-next {
          right: -30px;
        }
      }
    }
  }
}

.contact-form {
  .address-form__accept_privacy-container {
    width: 100%;
  }
}

.menu-item-container--childcount-5.first {
  max-width: 23%;
}

.menu-item-container--childcount-4.expanded {
  max-width: 18%;
}

.page-wrapper {
  height: 100%;
}

#password_reset {
  height: auto !important;
}

.product-full {
  .spp_delivery_info {
    .shipping-cost-widget {
      .shipping-cost {
        p:nth-of-type(2) {
          span:nth-child(1) {
            width: 140%;
            float: left;
          }
        }
      }
    }
    a.shipping_calc {
      display: block;
      text-decoration: underline;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.15em;
      padding: 4px 0 10px 0;
    }
  }
}

#ship-calc-overlay {
  h3 {
    font-family: 'Optima Display Roman' !important;
  }
  .country-container {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    font-family: 'AkzidenzGrotesk W1G' !important;
    input,
    select {
      width: 100%;
      float: left;
    }
    select {
      font-family: 'AkzidenzGrotesk W1G' !important;
      font-size: 12px;
      background-color: white !important;
      color: #040a2b !important;
      border-color: #b3b3b3;
      background-image: url('/media/images/background-images/background-image13.png');
    }
  }
  .calculation-result {
    .shipping-type {
      font-weight: bold;
    }
  }
  .close-popup,
  .input-btn {
    padding: 8px 10px;
    background: #040a2b;
    border: none;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    color: #ffffff;
  }
  .close-popup {
    float: right;
    margin-right: 2px;
  }
  .submit_popup {
    margin-top: 15px;
  }
  .missing_region,
  .missing_city {
    color: red !important;
  }
}

.shipping-cost-widget {
  .shipping-price {
    margin-right: 5px;
    padding-bottom: 10px;
  }
  .shipping-title {
    font-weight: bold;
  }
  .country-container {
    display: block;
  }
  .city_region {
    text-transform: uppercase;
  }
}

#shipping_calculator {
  .calculation-result {
    clear: both;
  }
}

.ship-cost-popup {
  #cboxContent {
    #cboxClose {
      right: 25px !important;
    }
  }
}

.mpp-gifts-page {
  .field-content {
    div.mpp {
      padding-bottom: 0px;
      margin-top: 0px;
    }
  }
}

div {
  &.sticky-footer-right-menu-menu {
    right: 0px;
  }
}

.product {
  &__inventory-status {
    .button {
      &--dark {
        display: inline-block;
      }
    }
  }
}

.sd-grid-formatter {
  .content-formatter {
    &__rendered {
      &.slick-not-needed {
        flex-wrap: nowrap;
      }
    }
  }
}

.section-uznat-bolshe-pro-novinku-anr-matrix {
  .tabbed-products-block {
    .grid--mpp {
      &__carousel {
        .grid--mpp {
          &__item {
            .product-brief {
              min-height: 480px;
            }
          }
        }
      }
    }
  }
}
