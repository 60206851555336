.trust-mark-elc-nodeblock {
  float: left;
  .trust_mark_header {
    position: relative;
    width: 80px;
    height: 80px;
    .trust_mark_header__link {
      position: absolute;
      width: 70px;
      height: 80px;
      top: -22px;
      background-image: url('/media/images/global/trustmark_ru.png');
      background-repeat: no-repeat;
      text-indent: -9999px;
    }
  }
}

.page-branding__logo {
  float: right;
}

.page-utilities__cart {
  @media #{$medium-up} {
    left: 45px;
  }
  @media #{$xlarge-up} {
    left: 62px;
  }
}

.brand-renutriv,
#beauty_feed,
.is-alternate {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3-white.png');
      }
    }
  }
}

.checkout-buttons-container {
  .checkout-buttons.disabled {
    background-color: #d4d4d4;
    cursor: default;
    border: none;
  }
}

#colorbox {
  &.welcome15-overlay {
    #cboxWrapper {
      #cboxContent {
        overflow: visible;
        .email_popover {
          .email_popover__content {
            p {
              letter-spacing: 0.15em;
              line-height: 26px;
              margin: 17px 0 22px 0;
              strong {
                font-family: $akzidenz;
                font-size: 15px;
                line-height: 26px;
                letter-spacing: 0.15em;
              }
            }
            strong {
              font-family: $optimalight;
              font-size: 38px;
              line-height: 42px;
              letter-spacing: -0.04em;
            }
            padding: 0 0 10px 0px;
            margin-top: 0;
            .email_input {
              input {
                &.form-text {
                  width: 250px;
                }
              }
            }
          }
          .email_popover__disclaimer {
            padding: 0 0 10px 0px;
            a {
              color: #9eafff;
              text-transform: uppercase;
              font-size: 12px;
              letter-spacing: 0.15em;
              font-weight: bold;
              display: block;
              margin-top: 24px;
            }
          }
          .email_popover__social_icons {
            top: 400px;
            text-align: left;
          }
          text-align: left;
        }
      }
      height: 100% !important;
      overflow: visible;
    }
    height: 100% !important;
  }
}

.page-utilities {
  .user-login-state {
    height: 0;
  }
}

.product_brief__price {
  // Override the product_brief__price to be lower case, fixing the currency display
  text-transform: none;
  margin-top: 40px;
}

.cart-confirm__price,
.product_brief__price {
  &-loyalty {
    display: inline-block;
  }
}

.product-full__price {
  &-container {
    width: 125px;
    display: table-cell;
    &.product-full__price-has_size,
    .product-full__price-size-select-container & {
      min-width: 225px;
    }
  }
  &-description {
    display: table-cell;
    vertical-align: middle;
  }
  &-loyalty {
    display: table;
    margin: 10px 0;
    &.active {
      font-weight: bold;
    }
    &.tier3,
    &.tier2,
    &.tier1 {
      margin: 0;
    }
    &--link a {
      font-weight: normal;
    }
  }
  &-text {
    margin-right: 20px;
    font-size: inherit;
    display: block;
  }
}
/* 7858 */
.pcem_layout_1 {
  .pc_em__1_swatch_dot_7.pc_em_swatch_selected,
  .pc_em__1_swatch_dot_8.pc_em_swatch_selected,
  .pc_em__1_swatch_dot_9.pc_em_swatch_selected,
  .pc_em__1_swatch_dot_10.pc_em_swatch_selected {
    opacity: 0 !important;
  }
  #pc_em_swatch_label_7,
  #pc_em_swatch_label_8,
  #pc_em_swatch_label_9,
  #pc_em_swatch_label_10 {
    opacity: 0 !important;
    color: #000 !important;
  }
}

.pcem_layout_3 {
  .pc_em__3_swatch_dot_15.pc_em_swatch_selected {
    opacity: 0 !important;
  }
  #pc_em_swatch_label_15 {
    opacity: 0 !important;
    color: #000 !important;
  }
}

.product-full__price {
  &-container {
    &.product-full__price-has_size,
    .product-full__price-size-select-container & {
      min-width: 156px;
    }
  }
}

.page-utilities__account {
  @media #{$medium-up} {
    margin-top: -5px;
  }
}

.product-full__shade-filter {
  &.is_intensity {
    display: none;
  }
}

.spp-itemset-pure-color-love-spp-related-content-item-set {
  .product-full__shade-filter {
    &.is_intensity {
      display: block;
    }
  }
}

.product-full__price-psize {
  padding-left: 10px;
}

.section-double-wear-or-nothing {
  ul.selectBox-options-top {
    top: 55% !important;
    bottom: 0;
    position: absolute !important;
  }
}

.multi_use_mpp_v1 {
  h1.headline_content__headline.custom_size {
    font-family: OptimaEL !important;
  }
}

.checkout-co-enabled {
  .page-wrapper {
    .checkout {
      height: auto;
      .sign-in-panel {
        .new-or-returning-radios {
          width: auto;
          .new-customer,
          .returning-customer {
            &.form-item {
              clear: both;
              float: none;
            }
          }
        }
        &.substate-return-user {
          #return-account-h {
            display: block;
          }
        }
      }
      &__new-account,
      &__return-user {
        .adpl {
          input[type='text'],
          input[type='password'] {
            height: 4.5em;
            & + label {
              line-height: 3.2;
            }
          }
        }
      }
    }
  }
}

.multi_use_tout_v1 {
  &.tout_module_wrapper {
    margin-top: 100px;
  }
}

.footer-find-us {
  .footer-social-links {
    .vk {
      background-image: url(/media/export/cms/homepage/VK-icon.png);
      background-repeat: no-repeat;
      height: 24px;
      width: 35px;
      margin: 0 15px;
    }
    .vk:hover {
      background-image: url(/media/export/cms/homepage/VK-icon-hover.png);
    }
  }
}

.waitlist {
  & &__terms_link {
    padding: 0;
    #ACCEPTED_PRIVACY_POLICY {
      & ~ label::after {
        border: 1px solid $color-white;
        background-image: url('/media/images/background-images/background-image12.png');
      }
    }
    label {
      letter-spacing: 0.05em;
    }
  }
}

.product-full {
  &__price-size-select-container {
    .product-price-size {
      &__wrapper {
        display: none;
      }
    }
  }
}
