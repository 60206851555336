$xmedium-down: 1024px;

.page-products-18449 {
  .mpp__header {
    padding-left: 1% !important;
    font-size: 33px;
  }
}

.brand-renutriv {
  .selectBox-label {
    padding: 0 1px 0 10px !important;
  }
}

.brand-aerin {
  .page-main {
    h3.discover-more__header {
      font-family: 'futurabookc' !important;
    }
  }
}

.brand-renutriv {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        display: none !important;
      }
    }
  }
  .page-branding {
    .page-branding__logo {
      margin-left: -75px !important;
      position: static;
    }
  }
}

.hero-block__boutique {
  display: none;
}

.chat-parallax__content {
  max-width: none !important;
}

@media #{$medium-up} {
  #viewcart-panel {
    .cart-items {
      .cart-item__desc {
        width: 60%;
      }
    }
  }
}

.customer-service {
  form {
    &.contact-form {
      table {
        margin-bottom: 5px;
      }
    }
  }
}
