.enhanced_delivery_page {
  .page-wrapper {
    .pg_wrapper {
      .checkout_container {
        .checkout__content {
          #address-section-container {
            #shipping-address-info-panel {
              .address_controls {
                #choose-address {
                  .add-new-address {
                    .add-address-label {
                      bottom: 15px;
                      font-family: $akzidenz;
                    }
                  }
                }
              }
            }
          }
          .payment-group-cod {
            input[type='radio'] {
              & ~ label {
                &::after,
                &::before {
                  top: 16px;
                }
              }
            }
            p {
              font-family: $akzidenz;
              margin-#{$ldirection}: 15px;
            }
          }
          #delivery-options {
            .shipform_fields {
              span {
                .delivery-options {
                  position: absolute;
                }
              }
            }
          }
        }
        .checkout__sidebar {
          #order-summary-panel {
            #order-status {
              p {
                font-size: 14px;
                padding: 0;
              }
              table {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
  &#shipping {
    .page-wrapper {
      .pg_wrapper {
        .checkout_container {
          .checkout__content {
            #delivery-options {
              &-group {
                .delivery_option {
                  label {
                    .pickpoint-delivery-method-icon {
                      &::before {
                        #{$rdirection}: 70px;
                      }
                    }
                    .home-delivery-method-icon {
                      &::before {
                        #{$ldirection}: 15px;
                      }
                    }
                  }
                }
              }
            }
            .checkout-page-title {
              font-size: 16px;
              line-height: 1;
              margin-bottom: 0;
              padding-bottom: 0;
              text-align: $ldirection;
            }
          }
        }
      }
    }
  }
  &#payment {
    .checkout_container {
      .checkout__panel-title {
        border-bottom: 0;
        color: $color-grey-nero;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0.25px;
        line-height: 1.25;
        padding-bottom: 67px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  .checkout {
    &__content {
      .address_form_container {
        #delivery-options {
          margin-bottom: 50px;
          textarea {
            border: 1px solid $color-navy;
          }
          .shipform_fields {
            border: 1px solid $color-navy;
            padding: 14px 15px 11px;
          }
        }
        .country_container {
          margin-bottom: 22px;
        }
      }
      .payment-panel {
        border-top: 1px solid $color-navy;
        .payment-item-wrapper {
          border-bottom: 1px solid $color-navy;
          margin-bottom: 0;
          .flag-image {
            @include swap_direction(padding, 15px 15px 15px 30px);
            max-width: 562px;
            width: 100%;
            &::after,
            &::before {
              #{$ldirection}: 15px;
              top: 23px;
            }
            img {
              float: $rdirection;
              height: 23px;
              margin-top: 5px;
            }
            .payment-label {
              font-family: $akzidenz;
              margin-#{$ldirection}: 10px;
            }
          }
        }
      }
      .privacy_policy_container {
        input[type='checkbox'] ~ label {
          &::before,
          &::after {
            border: solid 1px $color-navy;
            content: '';
            position: absolute;
          }
          &::before {
            #{$ldirection}: 0;
            border-radius: 2px;
            color: $color-navy;
            height: 18px;
            width: 18px;
          }
          &::after {
            #{$ldirection}: 6px;
            background: none;
            border-width: 0 2px 2px 0;
            display: block;
            height: 11px;
            top: 5px;
            transform: rotate(45deg);
            width: 5px;
          }
        }
      }
    }
    .address_form_container {
      .country_container {
        select {
          border: 1px solid $color-navy;
          border-radius: 0;
          box-shadow: none;
          color: $color-light-gray;
          font-size: 12px;
          height: 54px;
        }
      }
    }
  }
  &#shipping {
    .click-and-collect {
      .personal-details {
        .form-item.left {
          float: none;
          &.city {
            margin-top: 20px;
          }
        }
        .phone1 {
          .adaptive-label-mode {
            & + label {
              &::before {
                transform: translateY(-24px) scale(0.8, 0.8);
              }
            }
          }
        }
      }
    }
    .checkout {
      &__sidebar {
        .error_messages {
          li {
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
    .click-and-collect {
      .local-collection {
        max-width: 562px;
        width: 100%;
        .local-collection-button {
          display: block;
          margin-#{$ldirection}: auto;
        }
        .form-submit {
          background: $color-navy;
          color: $color-white;
          font-size: 12px;
          height: 48px;
          letter-spacing: 1.25px;
          line-height: 4;
          max-width: 348px;
          text-align: center;
          width: 100%;
        }
      }
      .personal-details {
        .country {
          background: $color-azure-transparent;
          border: 1px solid $color-navy;
          height: 54px;
          .country-container {
            margin-top: 18px;
            padding-#{$ldirection}: 15px;
          }
          .country-label {
            display: none;
          }
        }
        .form-item {
          padding-#{$ldirection}: 0;
          width: 100%;
          label {
            &::before {
              color: $color-light-gray;
              font-size: 12px;
            }
          }
          input,
          select {
            border: 1px solid $color-navy;
            border-radius: 0;
            color: $color-navy;
            font-size: 12px;
            height: 54px;
          }
        }
      }
    }
    #address-section-container {
      .shipping-address-container .edit-shipping-address,
      .shipping-address-form-container {
        .country_container {
          label {
            display: none;
          }
          border: 0;
          margin-bottom: 22px;
          padding: 0;
        }
      }
      .shipping-address-form-container {
        input[type='text'],
        textarea {
          &.adaptive-label-mode + label {
            &::before {
              transform: translateY(-24px) scale(0.8, 0.8);
            }
          }
        }
      }
    }
    .address_form_container {
      margin-bottom: 20px;
    }
  }
}
